import {Page} from "../../components/PrivateRoutePage"
import React, {ChangeEvent, FormEvent, MouseEventHandler, useState} from "react"
import {Layout} from "../../components/Layout"
import {Helmet, Link, useTranslation} from "gatsby-plugin-react-i18next"
import {ResponsiveContainer, SectionPreTitle, SectionTitle} from "../../components/Shared"
import {getTheme} from "../../theme"
import {TextCenter, TextCenteredGrid} from "../diy/components/Shared"
import rgbdualImageFr from "../../data/images/rgbdual/title-fr.jpg"
import rgbdualImageEn from "../../data/images/rgbdual/title-en.jpg"
import rgbDualVideoMP4 from "../../data/videos/recalbox-rgb-dual-atomik.mp4-processed.mp4"
import rgbDualVideoWebm from "../../data/videos/recalbox-rgb-dual-atomik.mp4.webm"
import rgbDualRotationImage from "../../data/images/rgbdual/rotation-irl.gif"
import metroidGif from "../../data/images/rgbdual/metroid.gif"
import boombox from "../../data/images/rgbdual/features/boombox-2.jpeg"
import {Box, Button, Grid, LinearProgress, TextField} from "@material-ui/core"
import styled from "@emotion/styled"
import {MdTypo, TransMdTypo} from "../../components/i18n/TransMdTypo"
import {StaticImage} from "gatsby-plugin-image"
import YouTube from "react-youtube"
import {RecalboxRGBDualManualPage} from "./RecalboxRGBDualManualPage";
import {RRGBDRoadmapPage} from "./RRGBDRoadmapPage"
import {HomePage} from "../../pages"
import {useDispatch} from "react-redux";
import {notification} from "../notification/notificationSlice";
import {RecalboxRGBDualOrderPage} from "./order/RecalboxRGBDualOrder";

const FullWidthButton = styled(Button)({
  width: "100%"
})

const TopMargedGrid = styled(Grid)({
  marginTop: "2em"
})

const KSTitle = styled(TransMdTypo)({
  fontWeight: "bold",
  fontSize: "1.5em",
  marginBottom: "1.5em",
  marginTop: "1.5em"

})
const KSSection = styled(Box)({
  marginTop: "3em",
  "& p": {
    marginBottom: "1.5em"
  },
  img: {
    maxWidth: "100%"
  },
})

const StickyButton = styled(FullWidthButton)({})

const isOnKickstarter = process.env.GATSBY_KS_END_DATE ? true : false
const kickstarterEndDate = process.env.GATSBY_KS_END_DATE ? new Date(process.env.GATSBY_KS_END_DATE) : new Date()


const HighlightTransMdTypo = styled(TransMdTypo)({
  fontWeight: "bold",
  fontSize: "2em"
})

const getPercentage = () => {
  return 100 - (100 * getDaysLeft() / 30)
}

const getDaysLeft = () => {
  return Math.floor((kickstarterEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
}

const isEmail = (email: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)


const MauticEmailForm = (props: {text:string, placeholder:string, value:string, error:boolean
  handleSubmit: () => void,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void}) => {

    return <TextCenteredGrid container spacing={1} css={{marginTop: "3em"}}>
      <TextField error={props.error} css={{width: "100%"}} color="primary" variant="outlined"
                 placeholder={props.placeholder}
                 type="email"
                 label="Email" value={props.value} onChange={props.handleChange}
                 id="email-address"/>
      <Button onClick={props.handleSubmit} css={{width: "100%", marginTop: "1em"}} variant="outlined" color="secondary"
              type="submit" disabled={props.error}>
        {props.text}
      </Button>
    </TextCenteredGrid>
}

export const RGBDual = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setError(false)
  }
  const handleSubmit = () => {
    if(!isEmail(email)){
      setError(true)
    }else{
      const data = new FormData()
      data.append("mauticform[formId]", "3")
      data.append("mauticform[formName]", "rrgbdbackinstock")
      data.append("mauticform[lang1]", i18n.language)
      data.append("mauticform[email]", email)
      data.append("mauticform[messenger]", "1")

      fetch("https://market.recalbox.com/form/submit?formId=3", {
        mode: "cors",
        method: "POST",
        body: data,
        headers : {
          "X-Requested-With": "XMLHttpRequest",
        }

      }).then(function (res) {
        dispatch(notification(t("rgbdual^Thank you! You will be the first to know when the Recalbox RGB Dual is back in stock!"), "success"))
      }, function (e) {
        dispatch(notification(t("rgbdual^An error occured!"), "error"))
      })
    }
  }
  const {t, i18n} = useTranslation()
  return <Layout
    title={"Recalbox RGB Dual"}
    description={t("rgbdual^Bring your CRT screens and TV back to life and play all your childhood games with RECALBOX RGB Dual!")}
    imageUrl={i18n.language === "fr" ? rgbdualImageFr : rgbdualImageEn}
    withTheme={getTheme(false, true)}>
    <ResponsiveContainer>
      <TextCenter>
        <SectionTitle>
          Recalbox RGB Dual
        </SectionTitle>
        <SectionPreTitle>
          {t("rgbdual^Bring your CRT screens and TV back to life and play all your childhood games with RECALBOX RGB Dual!")}
        </SectionPreTitle>
        <Button color={"secondary"} css={{margin: "1em"}} variant={"contained"}
                href={"https://shop.recalbox.com/collections/rgbdual"}>{t("rgbdual^Buy")}</Button>
        <Button color={"secondary"} css={{margin: "1em"}} variant={"outlined"} component={Link}
                to={RecalboxRGBDualManualPage.getUrl()}>{t("rrgbdmanual^Manual")}</Button>
      </TextCenter>
      <TopMargedGrid container spacing={2}>
        <Grid item sm={12} md={8}>
          <video controls poster={i18n.language === "fr" ? rgbdualImageFr : rgbdualImageEn}
                 css={{width: "100%", height: "100%", objectFit: "cover"}}>
            <source src={rgbDualVideoMP4} type="video/mp4"/>
            <source src={rgbDualVideoWebm} type="video/webm"/>
          </video>

        </Grid>
        <Grid item sm={12} md={4}>
          <Grid container spacing={2} css={{height: "100%"}}>
            <Grid item xs={12}>
              <TransMdTypo>
                rgbdual^Recalbox RGB Dual is back!
              </TransMdTypo>
            </Grid>
            <Grid item xs={12}>
              <Button variant={"contained"} color={"secondary"} href={"https://shop.recalbox.com/collections/rgbdual"}>{t("rgbdual^Buy a Recalbox RGB Dual")}</Button>
            </Grid>
          </Grid>
        </Grid>
      </TopMargedGrid>
      <Grid container>
        <Grid item sm={12} md={8}>
          <KSSection>
            <KSTitle>
              rgbdual^Story
            </KSTitle>
            <TransMdTypo>
              rgbdual^Bring your CRT screens and TV back to life and play all your childhood games with RECALBOX RGB
              Dual!
            </TransMdTypo>
            {i18n.language === "fr" ?
              <React.Fragment>
                <MdTypo>
                  Pas envie de tout lire jusqu'en bas ? Pas de soucis, on laisse notre Boby national aka
                  [@Elardramora](https://www.youtube.com/channel/UC3siKVVCA-fgufejlR5oF-Q) vous expliquer tout ca en
                  video !
                </MdTypo>
                <YouTube css={{width: "100%"}} videoId="FucJ1LIyddA"/>
              </React.Fragment>
              :
              <img src={rgbDualRotationImage}/>
            }
          </KSSection>

          <KSSection>
            <Box>
              <StaticImage key={"Recalbox Project"} src={"../../data/images/rgbdual/logo-recalbox-oneline.png"}
                           alt={"Recalbox Project"} placeholder={"blurred"}/>
            </Box>
            <TransMdTypo>
              rgbdual^Recalbox Description
            </TransMdTypo>
          </KSSection>
          <KSSection>
            <Box>
              <StaticImage key={"Recalbox RGB Dual Project"} src={"../../data/images/rgbdual/logo-rgb-dual-oneline.png"}
                           alt={"Recalbox RGB Dual Project"} placeholder={"blurred"}/>
              <StaticImage key={"Playing on Recalbox RGB Dual"} css={{maxWidth: "600px"}}
                           src={"../../data/images/rgbdual/playing-on-crt.jpg"}
                           alt={"Playing on Recalbox RGB Dual"} placeholder={"blurred"}/>
            </Box>
            <KSTitle>
              rgbdual^1 - Objective
            </KSTitle>
            <TransMdTypo>
              rgbdual^1 - Objective details
            </TransMdTypo>
            <StaticImage key={"Pixel Versus CRT 1"} src={"../../data/images/rgbdual/versus-card-girl.png"}
                         alt={"Pixel Versus CRT 1"} placeholder={"blurred"}/>
          </KSSection>
          <KSSection>
            <KSTitle>
              rgbdual^2 - What is Recalbox RGB Dual?
            </KSTitle>
            <TransMdTypo>
              rgbdual^It's a hat
            </TransMdTypo>
            <StaticImage key={"Recalbox RGB Dual on RPI4"}
                         src={"../../data/images/rgbdual/recalbox-rgb-dual-on-rpi4.jpg"}
                         alt={"Recalbox RGB Dual on RPI4"} placeholder={"blurred"}/>
            <TransMdTypo>
              rgbdual^2 - What is Recalbox RGB Dual details
            </TransMdTypo>
            <img src={metroidGif} alt={"Metroid on rgbdual"}/>
            <TransMdTypo>
              rgbdual^Recalbox RGB Dual prototype footage
            </TransMdTypo>
            <YouTube css={{width: "100%"}} videoId="bJibQeVnNJY"/>
          </KSSection>
          <KSSection>
            <KSTitle>
              rgbdual^Features
            </KSTitle>
            <TransMdTypo>
              rgbdual^Feature config chip title
            </TransMdTypo>
            <Box>

              <StaticImage key={"Configuration Chip"}
                           src={"../../data/images/rgbdual/features/configchip.jpg"}
                           alt={"Configuration Chip"} placeholder={"blurred"}/>
            </Box>
            <TransMdTypo>
              rgbdual^Feature config chip description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature pixel factory title
            </TransMdTypo>
            <StaticImage key={"Pixel Factory"}
                         src={"../../data/images/rgbdual/features/pixelfactory.jpg"}
                         alt={"Pixel Factory"} placeholder={"blurred"}/>
            <TransMdTypo>
              rgbdual^Feature pixel factory description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature boom box title
            </TransMdTypo>
            <Box>
              <img src={boombox}
                   alt={"Boom Box"}/>
            </Box>
            <TransMdTypo>
              rgbdual^Feature boom box description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature power up title
            </TransMdTypo>
            <Box>
              <StaticImage key={"Power Up"}
                           src={"../../data/images/rgbdual/features/powerup.jpg"}
                           alt={"Power Up"} placeholder={"blurred"}/>
            </Box>
            <TransMdTypo>
              rgbdual^Feature power up description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature automatic timing selection title
            </TransMdTypo>
            <Box>
              <StaticImage key={"Database"} src={"../../data/images/rgbdual/database.png"} alt={"Database"}
                           placeholder={"blurred"}/>
            </Box>
            <TransMdTypo>
              rgbdual^Feature automatic timing selection description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature configuration screen title
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature configuration screen description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature mode 50Hz title
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature mode 50Hz description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature 240p theme title
            </TransMdTypo>
            <Box>
              <StaticImage key={"240p Theme"} src={"../../data/images/rgbdual/theme.jpg"} alt={"240p Theme"}
                           placeholder={"blurred"}/>
            </Box>

            <TransMdTypo>
              rgbdual^Feature 240p theme description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature power users title
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature power users description
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature 240p test suite title
            </TransMdTypo>
            <TransMdTypo>
              rgbdual^Feature 240p test suite description
            </TransMdTypo>
            <Box key={"a key"}>
              <StaticImage key={"Versus metal slug"} src={"../../data/images/rgbdual/versus-card-metal-slug.png"}
                           alt={"Versus metal slug"} placeholder={"blurred"}/>
            </Box>

          </KSSection>
          <KSSection>
            <KSTitle>
              rgbdual^3 - The case
            </KSTitle>
            <TransMdTypo>
              rgbdual^3 - The case part1
            </TransMdTypo>
            <Button component={Link} to={RecalboxRGBDualOrderPage.getUrl()}>{t("rgbdual^Buy a Recalbox RGB Dual")}</Button>
            <StaticImage key={"RGB CASE + controllers"} src={"../../data/images/rgbdual/rgb-case-controllers.jpg"}
                         alt={"RGB CASE + controllers"} placeholder={"blurred"}/>

          </KSSection>

          <KSSection>
            <KSTitle>
              rgbdual^4 - Development
            </KSTitle>
            <TransMdTypo>
              rgbdual^4 - Development part1
            </TransMdTypo>
            <StaticImage key={"Prototype"} src={"../../data/images/rgbdual/prototype.jpg"} alt={"Prototype"}
                         placeholder={"blurred"}/>
            <TransMdTypo>
              rgbdual^4 - Development part2
            </TransMdTypo>
            <StaticImage key={"Labo"} src={"../../data/images/rgbdual/labo.jpg"} alt={"Labo"} placeholder={"blurred"}/>
          </KSSection>

          <KSSection>
            <KSTitle>
              rgbdual^5 - The RGB signal, 240p, 15KHz and all the rest title
            </KSTitle>
            <TransMdTypo>
              rgbdual^5 - The RGB signal, 240p, 15KHz and all the rest part1
            </TransMdTypo>
            <StaticImage key={"RGB VS SCART"} src={"../../data/images/rgbdual/versus-rgb-scart.jpg"}
                         alt={"RGB VS SCART"} placeholder={"blurred"}/>
            <TransMdTypo>
              rgbdual^5 - The RGB signal, 240p, 15KHz and all the rest part2
            </TransMdTypo>
            <StaticImage key={"Meme"} src={"../../data/images/rgbdual/meme-900x900.jpg"} alt={"Meme"}
                         placeholder={"blurred"}/>
          </KSSection>

          <KSSection>

            <KSTitle>
              rgbdual^They like us!
            </KSTitle>
            <a href={"https://www.brokestudio.fr/"} target={"_blank"}>
              <StaticImage src={"../../data/images/rgbdual/approval-broke.png"} alt={"broke studio"}
                           placeholder={"blurred"}/>
            </a>
            <a href={"https://www.hfsplay.fr//"} target={"_blank"}>
              <StaticImage src={"../../data/images/rgbdual/approval-hfs.png"} alt={"hfsplay"}
                           placeholder={"blurred"}/>
            </a>
            <a href={"http://www.ffviman.fr/"} target={"_blank"}>
              <StaticImage src={"../../data/images/rgbdual/approval-ffvi.png"} alt={"ffviman"}
                           placeholder={"blurred"}/>
            </a>
            <KSTitle>
              rgbdual^They talk about us!
            </KSTitle>
            <YouTube css={{width: "100%"}} videoId="rArno-4v1gg"/>
            <YouTube css={{width: "100%"}} videoId="Zn00tO71suI"/>
            <YouTube css={{width: "100%"}} videoId="WrzaUC2vibs"/>
            <YouTube css={{width: "100%"}} videoId="FucJ1LIyddA"/>
          </KSSection>

          <KSSection>
            <KSTitle>
              rgbdual^F.A.Q.
            </KSTitle>
            <TransMdTypo>
              rgbdual^F.A.Q. list
            </TransMdTypo>
          </KSSection>
        </Grid>
      </Grid>

    </ResponsiveContainer>
  </Layout>
}

const getUrl = () => "/recalbox-rgb-dual"
export const RGBDualPage: Page = {
  Page: RGBDual,
  getUrl,
  parentPage: () => HomePage,
  breadCrumb: {name: "Recalbox RGB Dual", link: getUrl()}
}
